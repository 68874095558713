<template>

  <v-btn v-if="tooltipPosition === 'none'"
         :type="computedType"
         :color="computedLookup?.color"
         exact
         icon
         :class="computedLookup?.classes"
         v-bind="$attrs"
         v-on="$listeners">

    <v-icon size="">{{ computedLookup?.icon }}</v-icon>

  </v-btn>

  <!--:color="color"-->

  <v-tooltip v-else
             :[tooltipPosition]="true">
    <template v-slot:activator="{ on }">
      <v-btn :class="computedLookup?.classes"
             :type="computedType"
             exact
             icon
             :color="computedLookup?.color"
             v-bind="$attrs"
             v-on="{...on, ...$listeners}"
      >
        <v-icon>{{ computedLookup?.icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ computedLookup?.tooltip }}</span>
  </v-tooltip>

</template>

<script>
  export default {
    name: 'BSGlyphButton',

    props: {
      // proppy https://vuejs.org/guide/components/props.html#prop-validation

      color: {
        type: String,
      },
      options: {
        type: Object,
      },
      /*
       options: {
       type: Object,
       // Object or array defaults must be returned from
       // a factory function. The function receives the raw
       // props received by the component as the argument.
       default(rawProps) {
       return {}
       },
       },
       */

      hoverShow: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'button',
        // required: true,
        /*
         validator: function(value) {
         return [ 'tear', 'close', 'delete', 'edit', 'create', 'download', 'menu', 'export', 'add' ].includes(value)
         },
         */
      },
      dark: {
        type: Boolean,
        default: false,
      },
      muted: {
        type: Boolean,
      },
      tooltipPosition: {
        type: String,
        default: 'top',
        validator: function(value) {
          return [ 'top', 'right', 'bottom', 'left', 'none' ].includes(value)
        },
      },
    },
    data() {
      return {
        lookup: {
          tear: {
            classes: [
              (this.dark ? 'bl-icon-button-dark' : 'bl-icon-button'),
              'bl-icon-button-tear',
            ],
            icon: 'mdi-rounded-corner',
            hoverIcon: 'mdi-close-circle',
            tooltip: 'Tear Off',
          },
          close: {
            classes: [
              (this.dark ? 'bl-icon-button-dark' : 'bl-icon-button'),
              'bl-icon-button-close',
            ],
            icon: 'mdi-close',
            // icon: 'mdi-close-thick',
            // icon: 'mdi-window-close',
            // icon: 'mdi-close-circle',
            // icon: 'mdi-close-circle-outline',
            hoverIcon: 'mdi-close-circle',
            tooltip: 'Close',
          },
          menu: {
            classes: [
              'bl-icon-button',
              // 'bl-icon-button-menu',
              (this.muted ? '' : 'slate--text'),
            ],
            icon: 'mdi-dots-vertical',
            tooltip: 'Actions',
          },
          deleteCircleOutline: {
            classes: [
              'bl-icon-button',
              'bl-icon-button-delete',
              (this.muted ? '' : 'danger--text'),
            ],
            icon: 'mdi-delete-circle-outline',
            color: 'danger',
            tooltip: 'Delete',
          },
          delete: {
            classes: [
              'bl-icon-button',
              'bl-icon-button-delete',
              (this.muted ? '' : 'danger--text'),
            ],
            icon: 'mdi-delete-outline',
            color: 'danger',
            tooltip: 'Delete',
          },
          circleEditOutline: {
            classes: [
              'bl-icon-button',
              'bl-icon-button-edit',
              // (this.muted ? '' : 'info--text'),
            ],
            // icon: 'mdi-square-edit-outline',
            icon: 'mdi-circle-edit-outline',
            // color: 'info',
            color: this.muted ? null : 'info',
            tooltip: 'Edit',
          },
          edit: {
            classes: [
              'bl-icon-button',
              'bl-icon-button-edit',
              (this.muted ? '' : 'info--text'),
            ],
            // icon: 'mdi-square-edit-outline',
            icon: 'mdi-pencil-outline',
            color: 'info',
            tooltip: 'Edit',
          },
          swap: {
            classes: [
              'bl-icon-button',
              'bl-icon-button-swap',
              (this.muted ? '' : 'info--text'),
            ],
            icon: 'mdi-arrow-left-right-bold-outline',
            // icon: 'mdi-arrow-left-right-bold-outline',
            color: 'info',
            tooltip: 'Swap',
          },
          create: {
            classes: [
              'bl-icon-button',
              'bl-icon-button-create',
              (this.muted ? '' : 'success--text'),
            ],
            icon: 'mdi-plus-circle-outline',
            color: 'success',
            tooltip: 'Create',
          },
          download: {
            classes: [
              'bl-icon-button',
              'bl-icon-button-edit',
              (this.muted ? '' : 'info--text'),
            ],
            icon: 'mdi-text-box-outline',
            color: 'info',
            tooltip: 'Download',
          },
          export: {
            classes: [
              'bl-icon-button',
              'bl-icon-button-edit',
              (this.muted ? '' : 'info--text'),
            ],
            icon: 'mdi-export',
            tooltip: 'Export',
          },
          add: {
            classes: [
              'bl-icon-button',
              'bl-icon-button-add',
              (this.hoverShow ? 'hover-show' : ''),
              (this.muted ? '' : 'success--text'),
            ],
            icon: 'mdi-playlist-play',
            color: 'success',
            tooltip: 'Add',
          },
        },
      }
    },
    components: {},
    mixins: [],
    watch: {},
    computed: {
      computedType: function() {
        // console.log('computedType')
        let retVal = 'button'
        if ([ 'submit' ].includes(this.type)) {
          retVal = this.type
        }
        return retVal
      },
      computedLookup: function() {
        // console.log('computedLookup')

        const type = this.type
        // console.log('computedLookup type', type)
        const color = this.color
        // console.log('computedLookup color', color)
        const computedPassedClasses = this.computedPassedClasses
        // console.log('computedLookup computedPassedClasses', computedPassedClasses)

        let options
        let optionsDefault = {
          classes: [
            ...computedPassedClasses,
            'bl-icon-button',
            (this.hoverShow ? 'hover-show' : null),
            // (this.muted ? '' : 'success--text'),
          ],
          icon: 'mdi-plus',
          color: this.color,
          tooltip: '_DEFAULT_TOOLTIP_',
        }
        // console.log('computedLookup optionsDefault', optionsDefault)

        if (this.type && this.type !== 'submit') {
          // options = this.lookup[type]
          options = {
            ...optionsDefault.classes,
            ...this.lookup[type],
          }
          // console.log('computedLookup type', type, options)
        } else if (this.options) {
          options = {
            ...optionsDefault.classes,
            ...this.options,
          }
          // console.log('computedLookup options', type, options)
        } else {
          options = optionsDefault
          // console.log('computedLookup type else', type, options)
        }
        // console.log('computedLookup type return', type, options)
        // console.log('computedLookup ////////////////////////////////////////////////////////////////')

        return options
      },
      computedPassedClasses: function() {
        // console.log('computedPassedClasses')

        let classArray = []
        const staticClass = this.$vnode.data.staticClass
        // console.log('computedPassedClasses staticClass', staticClass)

        if (staticClass) {
          classArray = staticClass.split(' ')
        }
        // console.log('computedPassedClasses classArray', classArray)

        return classArray
      },
      computedAttrs: function() {
        const attrs = { ...this.$attrs }
        attrs.class = this.$vnode.data.staticClass
        attrs.style = this.$vnode.data.staticStyle
        // console.log('computedAttrs attrs', attrs)

        return attrs
      },

    },
    methods: {},
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  :deep(.v-icon).v-icon::after {
    border-radius: 0;

    // background-color: currentColor;
    // border-radius: 50%;
    // content: '';
    // display: inline-block;
    // height: 100%;
    // left: 0;
    // opacity: 0;
    // pointer-events: none;
    // position: absolute;
    // top: 0;
    // transform: scale(1.3);
    // width: 100%;
    // transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  }
</style>
