<!--
  - Copyright ThreatBlockr Inc 2023-2024
  - ejohnson created
  -->

<!--TODO: https://github.com/vuetifyjs/vuetify/issues/11600#issuecomment-642273351-->
<template>
  <div class="bs-table-container"
       :class="{ 'debug-new': debug }">

    <v-data-table v-model="selectedTableItems"
                  ref="bsTable"
                  :items="computedTableItems"
                  :item-key="itemKey"
                  :loading="loading"
                  :headers="computedHeaders"
                  :search="computedDefaultPagination.search"
                  :show-expand="showExpand"
                  :show-select="showSelect"
                  :disable-pagination="disablePagination"
                  checkbox-color="blue"
                  expand-icon="mdi-chevron-right"
                  :class="computedTableClasses"

                  :header-props="headerProps"
                  :footer-props="footerProps"

                  :items-per-page="computedDefaultPagination.perPage"
                  :page.sync="computedDefaultPagination.page"
                  :sort-by="computedDefaultPagination.sortColumn"
                  :sort-desc="computedDefaultPagination.sortDirection === 'descending'"

                  :hide-default-footer="true"
                  :hide-default-header="true"

                  v-bind="computedAttrs"
                  v-on="$listeners"

                  @pagination="eventHandlerPagination"
                  @page-count="eventHandlerPageCount"

                  @update:sort-by="eventHandlerSort($event, 'sortColumn')"
                  @update:sort-desc="eventHandlerSort($event, 'sortDirection')"
                  @update:options="eventHandlerOptions">

      <!--=========================================================================================-->
      <!--=========================================================================================-->

      <template v-slot:top>
        <!--==============================-->
        <!--==============================-->
        <slot v-if="showHeader === true"
              name="top">

          <div class="header-content d-flex flex-column">

            <div v-if="hasContentForHeaderTop"
                 class="header-top d-flex flex-gap-4"
                 :class="bsHeaderProps.class.header.top"
                 style="">
              <div v-if="hasContentForHeaderTopLeft"
                   class="top-left d-flex align-center flex-gap-2 flex-grow-1">
                <div v-if="title"
                     class="d-flex flex-gap-2 align-baseline">
                  <!--==============================-->
                  <!--==============================-->
                  <slot name="title">
                    <div v-if="title"
                         class="title"
                         :class="bsHeaderProps.class.title">{{ title }}
                    </div>
                  </slot>
                  <!--==============================-->
                  <!--==============================-->

                  <!--==============================-->
                  <!--==============================-->
                  <slot name="subtitle">
                    <div v-if="paginationString.length"
                         class="subtitle text-subtitle-1 gray--text nowrap">({{ paginationString }})
                    </div>
                  </slot>
                  <!--==============================-->
                  <!--==============================-->
                </div>
                <!--==============================-->
                <!--==============================-->
                <slot name="topLeft"></slot>
                <!--==============================-->
                <!--==============================-->

                <!--=========================================================================================-->
                <!--          Start AutoDiscriminators topLeft -->
                <!--=========================================================================================-->
                <template v-if="autoDiscriminators === 'topLeft'">
                  <slot v-for="(header, index) in computedFilterHeaders"
                        :name="header.value">
                    <bs-table-unique-select v-if="header.filterType === 'select'"
                                            v-model="localDiscriminators[header.value]"
                                            :key="index"
                                            :disabled="loading || items.length === 0"
                                            :header="header"
                                            :items="computedTableItems"
                                            :loading="loading"
                                            :multiple="header.filterType === 'selectMultiple'"
                                            :menu-props="{ offsetY: true }"
                                            :property="header.value"
                                            class="discriminator bs-table-unique-select"
                                            :sticky="sticky"
                                            dense
                                            hide-details
                                            outlined
                                            preserve-order
                                            select-item
                                            single-line
                                            v-on:click.native.stop=""/>

                    <bs-datetime-range-picker v-else-if="header.filterType === 'dateRange'"
                                              v-model="localDiscriminators[header.value]"
                                              :confirm="true"
                                              :inline="false"
                                              :separate-date="true"

                                              :text-field-props="{ dense: true }"

                                              :disabled="loading || items.length === 0"

                                              :clearable="true"
                                              :sticky="false"

                                              class="discriminator bs-table-daterange"
                                              hide-details="auto"
                                              placeholder="All Dates"

                                              :items="computedTableItems"
                                              v-on:click.native.stop=""/>
                  </slot>
                  <bs-discriminator-reset v-if="computedFilterHeaders.length > 1"
                                          :discriminators="discriminators"
                                          v-on:click.native.stop=""/>
                </template>
                <!--=========================================================================================-->
                <!--          End AutoDiscriminators-->
                <!--=========================================================================================-->
                <bs-filter-textfield v-if="search === 'topLeft'"
                                     v-model="computedDefaultPagination.search"
                                     :disabled="items < 1"
                                     debounce
                                     dense
                                     label="Filter"
                                     v-bind="bsTableSearchProps"
                                     v-on:click.native.stop=""
                />

              </div>
              <div v-if="hasContentForHeaderTopRight"
                   class="top-right d-flex align-center justify-end flex-grow-1 flex-gap-4">
                <bs-filter-textfield v-if="search === 'topRight'"
                                     v-model="computedDefaultPagination.search"
                                     :disabled="items < 1"
                                     debounce
                                     dense
                                     label="Filter"
                                     v-bind="bsTableSearchProps"
                                     v-on:click.native.stop=""
                />

                <template v-if="autoDiscriminators === 'topRight'">
                  <slot v-for="(header, index) in computedFilterHeaders"
                        :name="header.value">
                    <bs-table-unique-select v-if="header.filterType === 'select'"
                                            v-model="localDiscriminators[header.value]"
                                            :key="index"
                                            :disabled="loading || items.length === 0"
                                            :header="header"
                                            :items="computedTableItems"
                                            :loading="loading"
                                            :multiple="header.filterType === 'selectMultiple'"
                                            :menu-props="{ offsetY: true }"
                                            :property="header.value"
                                            class="discriminator bs-table-unique-select"
                                            :sticky="sticky"
                                            dense
                                            hide-details
                                            outlined
                                            preserve-order
                                            select-item
                                            single-line
                                            v-on:click.native.stop=""/>

                    <bs-datetime-range-picker v-else-if="header.filterType === 'dateRange'"
                                              v-model="localDiscriminators[header.value]"
                                              :confirm="true"
                                              :inline="false"
                                              :separate-date="true"

                                              :text-field-props="{ dense: true }"

                                              :disabled="loading || items.length === 0"

                                              :clearable="true"
                                              :sticky="false"

                                              class="discriminator bs-table-daterange"
                                              hide-details="auto"
                                              placeholder="All Dates"

                                              :items="computedTableItems"
                                              v-on:click.native.stop=""/>
                  </slot>
                  <bs-discriminator-reset v-if="computedFilterHeaders.length > 1"
                                          :discriminators="discriminators"
                                          v-on:click.native.stop=""/>
                </template>

                <!--==============================-->
                <!--==============================-->
                <slot name="topRight"></slot>
                <!--==============================-->
                <!--==============================-->
              </div>
            </div>

            <div v-if="hasContentForHeaderBottom"
                 class="header-bottom d-flex flex-gap-4"
                 :class="bsHeaderProps.class.header.bottom"
                 style="">
              <div v-if="hasContentForHeaderBottomLeft"
                   class="bottom-left d-flex flex-gap-4 align-center flex-grow-1">
                <!--==============================-->
                <!--==============================-->
                <slot name="bottomLeft"></slot>
                <!--==============================-->
                <!--==============================-->

                <!--=========================================================================================-->
                <!--          Start AutoDiscriminators bottomLeft -->
                <!--=========================================================================================-->
                <template v-if="autoDiscriminators === 'bottomLeft'">
                  <slot v-for="(header, index) in computedFilterHeaders"
                        :name="header.value">
                    <bs-table-unique-select v-if="header.filterType === 'select'"
                                            v-model="localDiscriminators[header.value]"
                                            :key="index"
                                            :disabled="loading || items.length === 0"
                                            :header="header"
                                            :items="computedTableItems"
                                            :loading="loading"
                                            :multiple="header.filterType === 'selectMultiple'"
                                            :menu-props="{ offsetY: true }"
                                            :property="header.value"
                                            class="discriminator bs-table-unique-select"
                                            :sticky="sticky"
                                            dense
                                            hide-details
                                            outlined
                                            preserve-order
                                            select-item
                                            single-line
                                            v-on:click.native.stop=""/>

                    <bs-datetime-range-picker v-else-if="header.filterType === 'dateRange'"
                                              v-model="localDiscriminators[header.value]"
                                              :confirm="true"
                                              :inline="false"
                                              :separate-date="true"

                                              :text-field-props="{ dense: true }"

                                              :disabled="loading || items.length === 0"

                                              :clearable="true"
                                              :sticky="false"

                                              class="discriminator bs-table-daterange"
                                              hide-details="auto"
                                              placeholder="All Dates"

                                              :items="computedTableItems"
                                              v-on:click.native.stop=""/>
                  </slot>
                  <bs-discriminator-reset v-if="computedFilterHeaders.length > 1"
                                          :discriminators="discriminators"
                                          v-on:click.native.stop=""/>
                </template>
                <!--=========================================================================================-->
                <!--          End AutoDiscriminators-->
                <!--=========================================================================================-->
                <bs-filter-textfield v-if="search === 'bottomLeft'"
                                     v-model="computedDefaultPagination.search"
                                     :disabled="items < 1"
                                     debounce
                                     dense
                                     label="Filter"
                                     v-bind="bsTableSearchProps"
                                     v-on:click.native.stop=""/>

              </div>
              <div v-if="hasContentForHeaderBottomRight"
                   class="bottom-right d-flex align-center justify-end flex-grow-1 flex-gap-4">
                <bs-filter-textfield v-if="search === 'bottomRight'"
                                     v-model="computedDefaultPagination.search"
                                     :disabled="items < 1"
                                     debounce
                                     dense
                                     label="Filter"
                                     v-bind="bsTableSearchProps"
                                     v-on:click.native.stop=""
                />

                <!--=========================================================================================-->
                <!--          Start AutoDiscriminators bottomLeft -->
                <!--=========================================================================================-->
                <template v-if="autoDiscriminators === 'bottomRight'">
                  <slot v-for="(header, index) in computedFilterHeaders"
                        :name="header.value">
                    <bs-table-unique-select v-if="header.filterType === 'select'"
                                            v-model="localDiscriminators[header.value]"
                                            :key="index"
                                            :disabled="loading || items.length === 0"
                                            :header="header"
                                            :items="computedTableItems"
                                            :loading="loading"
                                            :multiple="header.filterType === 'selectMultiple'"
                                            :menu-props="{ offsetY: true }"
                                            :property="header.value"
                                            class="discriminator bs-table-unique-select"
                                            :sticky="sticky"
                                            dense
                                            hide-details
                                            outlined
                                            preserve-order
                                            select-item
                                            single-line
                                            v-on:click.native.stop=""/>

                    <bs-datetime-range-picker v-else-if="header.filterType === 'dateRange'"
                                              v-model="localDiscriminators[header.value]"
                                              :confirm="true"
                                              :inline="false"
                                              :separate-date="true"

                                              :text-field-props="{ dense: true }"

                                              :disabled="loading || items.length === 0"

                                              :clearable="true"
                                              :sticky="false"

                                              class="discriminator bs-table-daterange"
                                              hide-details="auto"
                                              placeholder="All Dates"

                                              :items="computedTableItems"
                                              v-on:click.native.stop=""/>
                  </slot>
                  <bs-discriminator-reset v-if="computedFilterHeaders.length > 1"
                                          :discriminators="discriminators"
                                          v-on:click.native.stop=""/>
                </template>
                <!--=========================================================================================-->
                <!--          End AutoDiscriminators-->
                <!--=========================================================================================-->

                <!--==============================-->
                <!--==============================-->
                <slot name="bottomRight"></slot>
                <!--==============================-->
                <!--==============================-->
              </div>
            </div>
          </div>

        </slot>
        <!--==============================-->
        <!--==============================-->
      </template>

      <!--=========================================================================================-->
      <!--=========================================================================================-->

      <template v-if="disablePagination === false"
                v-slot:footer>
        <div class="bs-table-footer d-flex align-center pa-1"
             style="border-top: 3px double rgba(0, 0, 0, 12%);">

          <bs-pagination v-show="items.length > 0"
                         v-bind.sync="computedDefaultPagination"
                         :filtered-total="filteredPagination.itemsLength"
                         :loading="loading"
                         :all-item="bsTablePaginationProps.allItem"
                         :debug="false"
                         class="nowrap ml-auto hide"
                         hide-first-last
                         pagination-style="buttons"
                         @changed:paginationString="setPaginationString"/>
        </div>
      </template>

      <!--=========================================================================================-->
      <!--=========================================================================================-->

      <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="slotData">
        <slot :name="slot" v-bind="{ ...slotData }"/>
      </template>

      <!-- pass through scoped slots -->
      <!--
            <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
              <slot :name="scopedSlotName" v-bind="slotData"/>
            </template>
      -->

      <!-- pass through normal slots -->
      <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
        <slot :name="slotName"/>
      </template>

      <!--=========================================================================================-->
      <!--=========================================================================================-->

      <template v-slot:progress>
        <slot name="progress"></slot>
      </template>

      <template v-slot:loading>
        <slot name="loading">
          <v-card class="pa-6" elevation="0">
            <v-card-title class="text-h5 justify-center gray--text">
              {{ loadingString }}
            </v-card-title>
          </v-card>
        </slot>
      </template>

      <template v-slot:no-data>
        <bs-table-no-data v-bind="bsTableNoDataProps"/>
      </template>

      <template v-slot:no-results>
        <v-card class="pa-6" elevation="0">
          <v-card-title class="text-h5 justify-center grey--text">No Matching Results</v-card-title>
        </v-card>
      </template>
      <!--=========================================================================================-->
      <!--=========================================================================================-->
      <!-- https://v2.vuetifyjs.com/en/api/v-data-table/#slots-header -->
      <template v-slot:header="{ props }">

        <thead>

        <tr v-if="superHeaders"
            class="header-row super-header-row">
          <th v-for="(header, index) in superHeaders"
              :key="`${ header.text }-${ index }-super-headers`"
              :class="header.class"
              :colspan="header.colspan">
            <span class="header-text" style="vertical-align: middle">{{ header.text }}</span>

          </th>
        </tr>
        <tr class="header-row" :class="{ disabled: loading || items < 1 }">
          <!--          https://v2.vuetifyjs.com/en/api/v-data-table/#props-headers-->
          <th v-if="showExpand" class="min" style=""></th>

          <th v-if="computedShowSelect"
              class="min">
            <v-simple-checkbox v-if="computedShowSelectAll"
                               v-model="selectAllCheckboxes"
                               :disabled="loading"
                               :indeterminate="isIndeterminate"
                               color="blue"
                               @click="btnSelectAll"
            />

          </th>

          <template v-for="header in computedHeaders">
            <th v-if="header.sortable"
                :key="`${header.value}-headers`"
                :class="header.class"
                @click="setSortColumn(header)">

              <div class="header-wrapper sort-header d-flex align-center">

                <div class="">
                  <v-icon v-if="header.icon">{{ header.icon }}</v-icon>
                  <div v-else
                       class="header-text" style="vertical-align: middle">
                    {{ header.text }}
                  </div>
                </div>

                <div v-if="header.sortable"
                     class="d-flex align-center justify-center"
                     style="height: 1.5rem; width: 1.5rem;">
                  <v-icon class="header-sort-icon"
                          small
                          style="">
                    {{ getSortIcon(header) }}
                  </v-icon>
                </div>

              </div>

            </th>

            <th v-else-if="hasContentForSlotName('tableActions') === true &&
                  ((computedHasActionColumn === true &&
                    header.value === 'actions') ||
                    header.value === 'tableActionHeader')"
                :key="`${header.value}-header`"
                :class="header.class"
                class="table-actions">
              <!--==============================-->
              <!--==============================-->
              <slot name="tableActions">
                <v-icon>mdi-dots-vertical</v-icon>
              </slot>
              <!--==============================-->
              <!--==============================-->
            </th>

            <!--
            <th v-else-if="hasContentForSlotName('tableActions') === true &&
                  ((computedHasActionColumn === true &&
                    header.value === 'actions') ||
                    header.value === 'tableActionHeader')"
                :key="`${header.value}-headers`"
                :class="header.class"
                class="table-actions">
              &lt;!&ndash;==============================&ndash;&gt;
              &lt;!&ndash;==============================&ndash;&gt;
              <slot name="tableActions">
                <v-icon>mdi-dots-vertical</v-icon>
              </slot>
              &lt;!&ndash;==============================&ndash;&gt;
              &lt;!&ndash;==============================&ndash;&gt;
            </th>
            -->

            <th v-else :key="`${header.value}-header`" :class="header.class">
              <div class="header-wrapper">
                <span class="header-text" style="vertical-align: middle">{{ header.text }}</span>
              </div>
            </th>

          </template>

          <!--
          <th v-if="autoDiscriminators === 'header'"
              class="min pa-0"
          >
          </th>
          -->

        </tr>

        <!--start header discriminators ---------------------------------------->
        <!--start header discriminators ---------------------------------------->
        <!--start header discriminators ---------------------------------------->
        <tr v-if="autoDiscriminators === 'header'"
            class="header-discriminator-row">
          <!--<tr v-if="hasColumnDiscriminators()" class="discriminator-row">-->
          <!--          https://v2.vuetifyjs.com/en/api/v-data-table/#props-headers-->
          <th v-if="showExpand" class="min"></th>
          <template v-for="(header, index) in computedHeaders">
            <th v-if="header.value === 'discriminatorActionHeader'"
                :key="`${header.value}-discriminator-reset`"
                :class="header.class" style="min-width: 28px;">
              <slot name="discriminatorActions">

                <bs-discriminator-reset :discriminators="discriminators"
                                        :search.sync="computedDefaultPagination.search"
                                        :icon="true"
                                        v-on:click.native.stop=""
                                        @search:reset="computedDefaultPagination.search = null">
                </bs-discriminator-reset>

              </slot>
            </th>
            <th v-else
                :key="`${header.value}`"
                :class="header.class"
                class=""
                style="">
              <slot v-if="header.filterable" :name="header.value">
                <!--<bs-table-unique-select v-if="header.filterType === 'select'"-->
                <bs-table-unique-select v-if="header.filterType === 'select' && isHeaderFilterable(header)"
                                        v-model="localDiscriminators[header.value]"
                                        :key="index"
                                        :disabled="loading || items.length === 0"
                                        :header="header"
                                        :items="computedTableItems"
                                        :loading="loading"
                                        :multiple="header.filterType === 'selectMultiple'"
                                        :menu-props="{ offsetY: true }"
                                        :property="header.value"
                                        class="header-discriminator header-select bs-table-unique-select"
                                        :sticky="sticky"

                                        :generic-placeholder="true"
                                        :outlined="false"
                                        :solo="true"
                                        :flat="true"
                                        :dense="true"
                                        append-icon="mdi-chevron-down"

                                        hide-details
                                        preserve-order
                                        select-item
                                        single-line
                                        v-on:click.native.stop=""/>
                <bs-filter-textfield v-if="header.filterType === 'textfield'"
                                     v-model="computedDefaultPagination.search"
                                     :disabled="items < 1"
                                     class="header-discriminator header-search"
                                     debounce
                                     append-icon="mdi-filter-outline"
                                     append-icon-alt="mdi-filter"
                                     :outlined="false"
                                     :solo="true"
                                     :flat="true"
                                     :dense="true"
                                     :label="null"
                                     placeholder="Filter"
                                     v-bind="bsTableSearchProps"
                                     v-on:click.native.stop=""/>

              </slot>
            </th>
          </template>

          <!--
          <th class="table-actions min pa-0">
            &lt;!&ndash;==============================&ndash;&gt;
            &lt;!&ndash;==============================&ndash;&gt;
            <slot name="discriminatorActions">
              <v-icon>mdi-close-circle</v-icon>
            </slot>
            &lt;!&ndash;==============================&ndash;&gt;
            &lt;!&ndash;==============================&ndash;&gt;
          </th>
          -->

        </tr>
        <!--start header discriminators ---------------------------------------->
        <!--start header discriminators ---------------------------------------->
        <!--start header discriminators ---------------------------------------->

        </thead>
      </template>
      <!--=========================================================================================-->
      <!--=========================================================================================-->
    </v-data-table>

    <v-row v-if="debug && computedIsDevelopment">
      <v-col>
        <bs-print :value="{ localDiscriminators }"/>
        <bs-print :value="{ discriminators }"/>

        <bs-print :value="{computedTableItems}"/>

        <!--<bs-print :value="{$props}" :deep="2"/>-->
        <!--<bs-print :value="{$props}" :deep="4"/>-->

        <!--<bs-print :value="{ sticky }"/>-->
      </v-col>

      <!--
      <v-col>
        &lt;!&ndash;<bs-print :value="{$slots}" :deep="2"/>&ndash;&gt;

        &lt;!&ndash;<bs-print :value="{ defaultPagination }"/>&ndash;&gt;
        &lt;!&ndash;<bs-print :value="{ computedDefaultPagination }"/>&ndash;&gt;
        &lt;!&ndash;<bs-print :value="{ bsTablePaginationProps }"/>&ndash;&gt;
      </v-col>
      <v-col>
        &lt;!&ndash;<bs-print :value="{$scopedSlots}" :deep="2"/>&ndash;&gt;

        &lt;!&ndash;<bs-print :value="{ filteredPagination }"/>&ndash;&gt;
      </v-col>
      <v-col>
        &lt;!&ndash;<bs-print :value="{ selectedTableItems }"/>&ndash;&gt;
      </v-col>
      -->

    </v-row>

  </div>

</template>

<script>
  import PermissionsMixin from '@/mixins/PermissionsMixin.js'
  import BSPagination from '@/components/BSPagination.vue'
  import BSTableNoData from '@/components/BSTableNoData.vue'
  import BSFilterTextfield from '@/components/BSFilterTextfield.vue'
  import BSDiscriminatorReset from '@/components/BSDiscriminatorReset.vue'
  import BSViewTitle from '@/components/BSViewTitle.vue'

  import BSTableUniqueSelect from '@/components/select/BaseSelect/Unique/BSTableUniqueSelect.vue'
  import BSDatetimeRangePicker from '@/views/unexpectedBlocks/BSDatetimeRangePicker.vue'
  import BSDatetimeRangePickerMixin from '@/mixins/BSDatetimeRangePickerMixin.js'
  import { formatISO, isValid, parseISO } from 'date-fns'

  export default {
    name: 'BSTable',
    props: {
      // https://vuejs.org/guide/components/props.html#prop-validation

      /*
       removed props
       titlePaginationString
       inline
       tableProps
       bsTableNoDataProps
       bsViewTitleProps

       */

      value: {
        default: undefined,
      },

      showExpand: {
        type: Boolean,
        default: false,
      },
      showSelect: {
        type: Boolean,
        default: false,
      },

      itemKey: {
        type: String,
        default: 'uuid',
      },


      showHeader: {
        type: Boolean,
        default: true,
      },


      title: {
        type: String,
      },
      subtitle: {
        type: String,
      },
      search: {
        type: String,
        default: 'bottomRight',
        validator(value) {
          // The value must match one of these strings
          return [
            'topRight',
            'topLeft',
            'bottomRight',
            'bottomLeft',
            'header',
            'none',
          ].includes(value)
        },
      },
      autoDiscriminators: {
        type: String,
        default: 'bottomLeft',
        validator(value) {
          // The value must match one of these strings
          return [
            'topRight',
            'topLeft',
            'bottomRight',
            'bottomLeft',
            'header',
            'none',
          ].includes(value)
        },
      },


      bsHeaderProps: {
        type: Object,
        default(rawProps) {
          return {
            class: {
              title: 'text-h5',
              header: {
                // top: 'pa-2 pl-4',
                top: 'pa-4',
                bottom: 'pa-4',
              },
            },
          }
        },
      },
      bsTablePaginationProps: {
        type: Object,
        default(rawProps) {
          return {
            allItem: true,
          }
        },
      },
      bsTableNoDataProps: {
        type: Object,
        default(rawProps) {
          return {
            allItem: true,
          }
        },
      },
      bsTableSearchProps: {
        type: Object,
        default(rawProps) {
          return {
            class: '',
          }
        },
      },

      loadingString: {
        type: String,
        default: 'Loading...',
      },

      loading: {
        type: Boolean,
        default: false,
      },

      debug: {
        type: Boolean,
        default: false,
      },

      roleHeaders: {
        type: Boolean,
        default: true,
      },

      highlightSort: {
        type: Boolean,
        default: true,
      },
      rowHover: {
        type: Boolean,
        default: true,
      },
      altRows: {
        type: Boolean,
        default: false,
      },
      altRowsBlue: {
        type: Boolean,
        default: false,
      },
      altRowsGraphite: {
        type: Boolean,
        default: false,
      },

      items: {
        type: Array,
        default(rawProps) {
          return []
        },
        required: true,
      },
      headers: {
        type: Array,
        required: true,
      },

      /////////////////////////////////////////////
      /////////////////////////////////////////////
      /////////////////////////////////////////////
      /////////////////////////////////////////////
      /////////////////////////////////////////////




      // start card props =====================================================
      // start card props =====================================================
      // start card props =====================================================
      /*
       inline: {
       type: Boolean,
       default: false,
       },
       */
      /*
       elevation: {
       type: Number,
       },
       */
      // end card props =====================================================
      // end card props =====================================================
      // end card props =====================================================


      // start title props =====================================================
      // start title props =====================================================
      // start title props =====================================================
      /*
       cardTitle: {
       type: Boolean,
       default: true,
       },
       */
      /*
       bsViewTitleProps: {
       type: Object,
       default(rawProps) {
       return {
       title: null,
       subtitle: null,
       paddingClass: 'pt-4 pr-4 pb-4 pl-4',
       count: this.paginationString,
       }
       },
       },
       */
      /*
       hasHeader: {
       type: Boolean,
       default: true,
       },
       */
      // end title props =====================================================
      // end title props =====================================================
      // end title props =====================================================


      // start table props =====================================================
      // start table props =====================================================
      // start table props =====================================================
      superHeaders: {
        type: Array,
      },
      discriminators: {
        type: Object,
        default: null,
      },
      sticky: {
        type: Boolean,
        default: true,
      },

      /*
       tableProps: {
       type: Object,
       default(rawProps) {
       return {}
       },
       },
       */

      showSelectAll: {
        type: Boolean,
        default: true,
      },


      // end table props =====================================================
      // end table props =====================================================
      // end table props =====================================================


      // start pagination props =====================================================
      // start pagination props =====================================================
      // start pagination props =====================================================
      paginationProps: {
        type: Object,
        default(rawProps) {
          return {}
        },
      },
      disablePagination: {
        type: Boolean,
        default: false,
      },

      // end pagination props =====================================================
      // end pagination props =====================================================
      // end pagination props =====================================================




      /*
       options: {
       type: Object,
       default(rawProps) {
       return {
       // page: 1,
       sortBy: [],
       // sortDesc: false,
       mustSort: false,
       }
       },
       },
       */



    },
    components: {
      'bs-view-title': BSViewTitle,
      'bs-pagination': BSPagination,
      'bs-table-no-data': BSTableNoData,
      'bs-filter-textfield': BSFilterTextfield,
      'bs-discriminator-reset': BSDiscriminatorReset,
      // 'bs-unique-items-select': BSUniqueItemsSelect,
      'bs-table-unique-select': BSTableUniqueSelect,
      'bs-datetime-range-picker': BSDatetimeRangePicker,
    },
    mixins: [
      PermissionsMixin,
      BSDatetimeRangePickerMixin,
    ],
    data() {
      return {
        // selectedTableItems: [],
        isIndeterminate: false,
        selectAllCheckboxes: false,


        // sortDesc: false,
        defaultPagination: {
          page: 1,
          perPage: 20,
          pages: 0,
          total: this.items.length,
          // total: 0,
          search: null,
          sortDirection: 'ascending',
          sortColumn: null,
        },
        paginationString: '',
        filteredPagination: {},


        headerProps: {
          sortIcon: 'mdi-chevron-up-circle',
          // sortIcon: 'mdi-chevron-up',
          // sortIcon: 'mdi-menu-up',
        },
        footerProps: {
          itemsPerPageOptions: [
            // 5,
            10, 20, 50, 100, -1,
          ],
        },
      }
    },
    watch: {
      items: {
        deep: false,
        immediate: false,
        handler(newValue, oldValue) {
          // console.log('watch items oldValue', oldValue)
          // console.log('watch items newValue', newValue)
          // console.log('watch items computedDefaultPagination', this.computedDefaultPagination)
          this.computedDefaultPagination.total = this.items.length
          // console.log('watch items computedDefaultPagination', this.computedDefaultPagination)

        },
      },
      selectedTableItems: {
        deep: false,
        handler(newValue, oldValue) {
          // console.log('selectedTableItems oldValue', oldValue)
          // console.log('selectedTableItems newValue', newValue)

          if (this.selectedTableItems.length === 0) {
            // console.log('selectAll NONE SELECTED')
            this.isIndeterminate = false
            this.selectAllCheckboxes = false
          } else if (
              this.computedTableItemsSelectable.length ===
              this.selectedTableItems.length
          ) {
            // console.log('selectAll ALL SELECTED')
            this.isIndeterminate = false
            this.selectAllCheckboxes = true
          } else {
            // console.log('selectAll INDETERMINATE')
            this.isIndeterminate = true
            this.selectAllCheckboxes = false
          }
        },
      },
    },
    computed: {

      computedDatetimeModel: {
        get() {
          // console.log('computedDatetimeModel get')
          let datetimeArray = null

          // ub specific
          const startDatetime = this.localDiscriminators.startDatetime
          const endDatetime = this.localDiscriminators.endDatetime
          // const startDatetime = this.computedEntriesDiscriminators.startDatetime
          // const endDatetime = this.computedEntriesDiscriminators.endDatetime
          // console.log('computedDatetimeModel startDatetime', startDatetime)
          // console.log('computedDatetimeModel endDatetime', endDatetime)
          // ub specific

          let startDatetimeObject = parseISO(startDatetime)
          let endDatetimeObject = parseISO(endDatetime)
          // console.log('localValue startDatetimeObject', startDatetimeObject)
          // console.log('localValue endDatetimeObject', endDatetimeObject)

          if (isValid(startDatetimeObject) && isValid(endDatetimeObject)) {
            datetimeArray = [
              startDatetimeObject,
              endDatetimeObject,
            ]
          }

          return datetimeArray
        },
        set(datetimeArray) {
          // console.log('computedDatetimeModel setdatetimeArray', datetimeArray)

          let startDatetime = datetimeArray[0]
          let endDatetime = datetimeArray[1]

          let startDatetimeIso = startDatetime ? formatISO(startDatetime) : null
          let endDatetimeIso = endDatetime ? formatISO(endDatetime) : null
          // console.log('localValue set update:startDatetime', startDatetimeIso)
          // console.log('localValue set update:endDatetime', endDatetimeIso)

          // ub specific
          this.localDiscriminators.startDatetime = startDatetimeIso
          this.localDiscriminators.endDatetime = endDatetimeIso
          // this.computedEntriesDiscriminators.startDatetime = startDatetimeIso
          // this.computedEntriesDiscriminators.endDatetime = endDatetimeIso
          // ub specific

        },
      },

      computedTableItems: function() {
        // console.log('computedTableItems')
        let itemsComputed = this.items
        // console.log('computedTableItems itemsComputed', itemsComputed)

        this.computedFilterHeaders.forEach((header) => {
          // console.log('computedTableItems computedFilterHeaders header', header)
          if (header._filter === undefined) {
            // console.log('computedTableItems _filter undefined', header.value)

            let discriminatorValue = this.discriminators[header.value]
            // console.log('computedTableItems _filter undefined discriminatorValue', discriminatorValue, header.value)

            if (header.filterType === 'select') {
              // console.log('computedTableItems _filter select computedTableItems discriminatorValue', discriminatorValue)

              // console.log('_filter select computedFilterHeaders header.value', header.value)
              itemsComputed = itemsComputed.filter((item) => {
                let headerValue = item[header.value]
                // console.log('_filter select computedTableItems headerValue', headerValue)
                // console.log('computedTableItems _filter undefined select item[header.value]', header.value, discriminatorValue, item[header.value])

                return (
                    // this.discriminators[header.value] === null ||
                    // item[header.value] === this.discriminators[header.value]

                    // discriminatorValue === null ||
                    // item[header.value] === discriminatorValue ||
                    discriminatorValue === null ||
                    discriminatorValue === undefined ||
                    item[header.value] === discriminatorValue
                )
              })
              // console.log('computedTableItems itemsComputed', itemsComputed)
              // console.log('computedTableItems _filter undefined select itemsComputed', itemsComputed, header.value)

            } else if (header.filterType === 'dateRange') {
              // console.log('computedTableItems _filter undefined dateRange', header.value, discriminatorValue)
              // let discriminatorValue = this.discriminators[header.value]
              // console.log('computedTableItems _filter dateRange computedTableItems discriminatorValue', discriminatorValue)

              if (discriminatorValue) {
                itemsComputed = itemsComputed.filter((item) => {
                  let headerValue = header.value
                  // console.log('computedTableItems _filter dateRange computedTableItems headerValue', headerValue)
                  // console.log('computedTableItems _filter dateRange item[header.value]', header.value, discriminatorValue, item[header.value])

                  let itemValue = item[headerValue]
                  // console.log('computedTableItems _filter dateRange computedTableItems itemValue', itemValue)

                  return !itemValue || this.datetimeInRange(itemValue, discriminatorValue)
                })

              }
              // console.log('computedTableItems _filter dateRange itemsComputed', itemsComputed, header.value)

            }
          } else {
            // console.log('computedTableItems _filter header._filter', header.value)
            itemsComputed = itemsComputed.filter(header._filter)
            // console.log('computedTableItems _filter header._filter itemsComputed', itemsComputed, header.value)

          }
        })
        // console.log('computedTableItems itemsComputed', itemsComputed)


        return itemsComputed
      },

      computedDefaultPagination: {
        get() {
          const defaultPagination = this.defaultPagination
          // console.log('computedDefaultPagination get defaultPagination', defaultPagination)
          return defaultPagination
        },
        set(value) {
          // console.log('computedDefaultPagination set value', value)
          this.defaultPagination = value
        },
      },

      computedShowSelect: function() {
        // console.log('computedShowSelect')
        // let retVal
        // console.log('computedShowSelect', this.$attrs)
        // const showSelectProp = get(this.$attrs, 'show-select')
        return this.showSelect
      },
      computedShowSelectAll: function() {
        // console.log('computedShowSelectAll')
        // let retVal
        // console.log('computedShowSelectAll', this.$attrs)
        // const showSelectAllProp = get(this.$attrs, 'show-select-all')
        // return (showSelectAllProp === true)
        return this.showSelectAll
      },
      selectedTableItems: {
        get() {
          // console.log('computed localFormModel get value', this.value)
          return this.value
        },
        set(value) {
          // console.log('computed localFormModel set value', value)
          this.$emit('input', value)
        },
      },

      computedTableItemsSelectable: function() {
        // console.log('_filter computedTableItems')
        let itemsComputed = this.computedTableItems
        // console.log('_filter computedTableItems itemsComputed', itemsComputed)
        itemsComputed = itemsComputed.filter((item) => {
          return item.isSelectable === true
        })
        // console.log('_filter computedTableItems itemsComputed', itemsComputed)

        return itemsComputed
      },

      // TODO: new style for v-model props
      // TODO: new hotness for v-model props
      localDiscriminators: {
        get() {
          return this.discriminators
        },
        set(value) {
          // this.$emit('input', value)
          // console.log('localDiscriminators value', value)
        },
      },

      computedHasActionColumn: function() {
        // console.log('computedHasActionColumn')
        let retVal = this.headers.find((object) => object.value === 'actions')
        // console.log('computedHasActionColumn', retVal)
        return retVal !== undefined
      },
      computedHeaders: function() {
        let headers = this.headers
        console.log('computedHeaders', headers)
        if (this.hasContentForSlotName('tableActions') === true && this.computedHasActionColumn === false) {
          headers = [
            ...headers,
            {
              text: '',
              value: 'tableActionHeader',
              sortable: false,
              filterable: false,
              align: 'start',
              class: [
                'pa-0',
                'px-2',
                'actions',
                'min',
                'tableActionHeader',
              ],
              cellClass: [],
            },
          ]
        }

        if (this.autoDiscriminators === 'header') {
          headers = [
            ...headers,
            {
              text: '',
              value: 'discriminatorActionHeader',
              sortable: false,
              filterable: false,
              align: 'start',
              class: [
                'pa-0',
                // 'px-2',
                'actions',
                'min',
                'discriminatorActionHeader',
              ],
              cellClass: [],
            },
          ]
        }

        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // set provided header class to an array if it isnt already
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        headers = headers.map(header => {
          if (typeof header.class == 'string') {
            header.class = [ header.class ]
          }
          return header
        })
        console.log('computedHeaders headers typeof', headers)

        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // start dont show display === false cols
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        headers = headers.filter(header => {
          return header.display !== false
        })
        console.log('computedHeaders headers header.display', headers)
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // end dont show display === false cols
        /////////////////////////////////////////////////////////////////////////////////////////////////////////

        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // start remove headers for role if prefered
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (this.roleHeaders) {
          headers = this.headersForAccess(headers)
        }
        console.log('computedHeaders headersForAccess', headers)
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // end remove headers for role if prefered
        /////////////////////////////////////////////////////////////////////////////////////////////////////////

        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // start set header classes
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        console.log('computedHeaders headers start', headers)

        headers = headers.map((header) => {
          /////////////////////////////////////////////////////////////////////////////////////////////////////////
          // start set header classes
          /////////////////////////////////////////////////////////////////////////////////////////////////////////
          let textAlign = `text-${ header.align || 'start' }`
          let filterable = header.filterable
          let sortable = header.sortable

          header.class = [ ...header.class, textAlign ]

          if (filterable) {
            header.class = [ ...header.class, 'filterable' ]
          }
          if (sortable) {
            header.class = [ ...header.class, 'sortable' ]
          }
          // set cell classes
          // header.cellClass = [ ...header.cellClass, textAlign ]

          return header
        })

        console.log('computedHeaders headers after', headers)
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // end set header classes
        /////////////////////////////////////////////////////////////////////////////////////////////////////////

        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // start set sort classes
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        headers = this.sortableHeaders(headers)
        console.log('computedHeaders highlightSort', headers)
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // end set sort classes
        /////////////////////////////////////////////////////////////////////////////////////////////////////////

        console.log('computedHeaders headers', headers)
        console.log('computedHeaders //////////////////')

        return headers
      },
      computedFilterHeaders: function() {
        let headers = this.computedHeaders
        console.log('computedFilterHeaders this.computedHeaders', this.computedHeaders)
        const discriminatorTypes = [ 'select', 'selectMultiple', 'dateRange', 'textfield' ]
        headers = headers.filter(header => {

          const text = header.text

          const isHeaderFilterable = this.isHeaderFilterable(header)
          console.log('computedFilterHeaders isHeaderFilterable', text, isHeaderFilterable)
          // console.log('### computedFilterHeaders ==')

          return isHeaderFilterable
        })
        // console.log('### computedFilterHeaders headers', headers)
        // console.log('### computedFilterHeaders //////////////////////////////////')
        console.log('computedFilterHeaders headers', headers)
        console.log('computedFilterHeaders //////////////////')

        return headers
      },
      computedFilterableHeaders: function() {
        // console.log('computedFilterableHeaders')
        let headers = this.computedFilterHeaders
        // let filterableHeaders = this.headers.filter(object => object.filterable === true)
        headers = headers.map((object) => {
          return object.value
        })
        return headers
      },

      computedTableClasses: function() {
        // console.log('computedTableClasses')
        // highlightSort
        // roleHeaders
        // rowHover
        // altRows

        let classes = {
          'bs-table': true,
          'bs-highlight-sort': this.highlightSort,
          'bs-role-headers': this.roleHeaders,
          'bs-row-hover': this.rowHover,
          'bs-alt-rows': this.altRows || this.altRowsGraphite || this.altRowsBlue,
          'bs-alt-rows-graphite': this.altRowsGraphite,
          'bs-alt-rows-blue': this.altRowsBlue,
        }

        return classes
      },
      computedFilterString: function() {
        // console.log('computedFilterString')
        let retVal
        const allItemCount = this.items.length // localize
        if (allItemCount === 0) return ''
        const filteredItemCount = this.filteredPagination.itemsLength
        const isFiltered = filteredItemCount !== null && allItemCount !== filteredItemCount

        if (isFiltered) {
          retVal = `${ filteredItemCount } filtered. ${ allItemCount } total`
        } else {
          retVal = `${ allItemCount } total`
        }
        return retVal
      },
      /*
       computedAttrs: function() {
       const attrs = { ...this.$attrs }
       attrs.class = this.$vnode.data.staticClass
       attrs.style = this.$vnode.data.staticStyle
       return attrs
       },
       */

      hasContentForHeader: function() {
        // console.log('hasContentForHeader')
        let retVal
        retVal = this.hasContentForHeaderTop || this.hasContentForHeaderBottom

        return retVal
      },
      hasContentForHeaderTop: function() {
        // console.log('hasContentForHeaderTop')
        let retVal
        retVal = this.hasContentForHeaderTopLeft || this.hasContentForHeaderTopRight
        return retVal
      },
      hasContentForHeaderBottom: function() {
        // console.log('hasContentForHeaderBottom')
        let retVal
        retVal = this.hasContentForHeaderBottomLeft || this.hasContentForHeaderBottomRight
        return retVal
      },
      hasContentForHeaderTopLeft: function() {
        // console.log('hasContentForHeaderTopLeft')
        let retVal
        retVal = this.hasContentForSlotName('topLeft') || this.title
        return retVal
      },
      hasContentForHeaderTopRight: function() {
        // console.log('hasContentForHeaderTopRight')
        let retVal
        retVal = this.hasContentForSlotName('topRight')
        // retVal = this.hasContentForSlotName('topRight') || this.search === 'topRight'
        return retVal
      },
      hasContentForHeaderBottomLeft: function() {
        // console.log('hasContentForHeaderBottomLeft')
        let retVal
        retVal = this.hasContentForSlotName('bottomLeft')
        return retVal
      },
      hasContentForHeaderBottomRight: function() {
        // console.log('hasContentForHeaderBottomRight')
        let retVal
        retVal = this.hasContentForSlotName('bottomRight')
        // retVal = this.hasContentForSlotName('bottomRight') || this.search === 'bottomRight'
        return retVal
      },

      computedAttrs: function() {
        const attrs = { ...this.$attrs }
        attrs.class = this.$vnode.data.staticClass
        attrs.style = this.$vnode.data.staticStyle
        return attrs
      },

    },

    methods: {
      headerDiscriminatorsReset: function() {
        // console.log('headerDiscriminatorsReset')
        let retVal

        return retVal
      },
      selectHasMoreThanOne: function(header) {
        // console.log('### selectHasMoreThanOne', header.text, header.filterType)
        let retVal = header.filterType === 'select' && this.hasMoreThanOneDistinctSelectItemForHeader(header)
        // console.log('### selectHasMoreThanOne retVal', retVal)

        // return true
        return retVal
      },
      hasMoreThanOneDistinctSelectItemForHeader: function(header) {
        // console.log('### hasMoreThanOneDistinctSelectItemForHeader', header.text)
        const distinctSelectItemsForHeaderCount = this.distinctSelectItemsForHeaderCount(header)
        // console.log('### hasMoreThanOneDistinctSelectItemForHeader count', header.text, distinctSelectItemsForHeaderCount)

        let retVal = distinctSelectItemsForHeaderCount > 1
        // console.log('### hasMoreThanOneDistinctSelectItemForHeader retVal', header.text, retVal)

        return retVal
      },
      distinctSelectItemsForHeaderCount: function(header) {
        // console.log('### distinctSelectItemsForHeaderCount', header.text)
        let retVal = this.distinctSelectItemsForHeader(header).length
        // console.log('### distinctSelectItemsForHeaderCount', header.text, retVal)

        return retVal
      },
      distinctSelectItemsForHeader: function(header) {
        // console.log('### distinctSelectItemsForHeader', header.text)

        let filteredItems

        if (header.filterItems === undefined) {
          filteredItems = this.items.map(item => {
            // console.log('filterItems this.items', item)
            return {
              text: item[header.value],
              value: item[header.value],
            }
          })
        } else {
          filteredItems = header.filterItems(this.items)
        }


        let distinctItems

        const set = new Set
        distinctItems = filteredItems.filter(object => {
          return object.value !== null && !set.has(object.value) && set.add(object.value)
        })

        // console.log('computedSelectItemsDistinct distinctItems', distinctItems)
        return distinctItems
      },
      hasContentForSlotName: function(slot) {
        // console.log('hasContentForSlotName', slot)
        // console.log('hasContentForSlotName this.$slots', this.$slots)
        // console.log('hasContentForSlotName this.$slots[slot]', this.$slots[slot])

        let userSlotContent = !!this.$slots[slot]
        // console.log('hasContentForSlotName userSlotContent', userSlotContent)
        let userScopedSlotContent = !!this.$scopedSlots[slot]
        // console.log('hasContentForSlotName userScopedSlotContent', userScopedSlotContent)

        let userContent = userSlotContent || userScopedSlotContent
        // console.log('hasContentForSlotName userContent', userContent)

        let search = this.search
        // console.log('hasContentForSlotName search', search)

        let hasSearch = search !== 'none' && search === slot
        // console.log('hasContentForSlotName hasSearch', hasSearch)

        let autoDiscriminators = this.autoDiscriminators
        // console.log('hasContentForSlotName autoDiscriminators', autoDiscriminators)

        let hasAutoDiscriminators = autoDiscriminators !== 'none' && this.autoDiscriminators === slot && this.computedFilterHeaders.length
        // console.log('hasContentForSlotName hasAutoDiscriminators', hasAutoDiscriminators)

        let retVal = userContent || hasSearch || hasAutoDiscriminators
        // console.log('hasContentForSlotName retVal', retVal)
        // console.log('hasContentForSlotName ////////////////////////')

        return retVal
      },
      /*
       slotHasContent: function(slot) {
       let userContent = !!this.$slots[slot]
       let search = this.search === slot
       let autoDiscriminators = this.autoDiscriminators === slot
       return userContent || search || autoDiscriminators
       },
       */

      setPaginationString: function(paginationString) {
        // console.log('setPaginationString paginationString', paginationString)
        this.$emit('changed:paginationString', paginationString)
        this.paginationString = paginationString
      },

      btnSelectAll: function() {
        // console.log('btnSelectAll')
        if (this.selectAllCheckboxes === true) {
          // console.log('selectAllCheckboxes newValue true')
          this.selectedTableItems = this.computedTableItemsSelectable
          // this.selectedTableItems = this.computedTableItems
        } else {
          this.selectedTableItems = []
        }
        this.$emit('click:select-all', this.selectAllCheckboxes)
      },

      hasColumnDiscriminators: function() {
        // console.log('hasColumnDiscriminators')
        return this.computedFilterableHeaders.some(
            (header) => !!this.$slots[header],
        )
      },
      isHeaderFilterable: function(header) {
        // console.log('isHeaderFilterable')

        const discriminatorTypes = [ 'select', 'selectMultiple', 'dateRange', 'textfield' ]

        const text = header.text
        // console.log('### isHeaderFilterable text', text)

        const filterType = header.filterType
        // console.log('### isHeaderFilterable filterType', text, filterType)


        const isFilteringSupported = discriminatorTypes.includes(filterType)
        // console.log('### isHeaderFilterable isFilteringSupported', text, isFilteringSupported)


        // selectHasMoreThanOne = true
        // todo: fix this
        // const selectHasMoreThanOne = this.selectHasMoreThanOne(header)

        // console.log('### isHeaderFilterable selectHasMoreThanOne', text, selectHasMoreThanOne)

        const isFilterable = header.filterable
        // const isFilterable = header.filterable && selectHasMoreThanOne
        // console.log('### isHeaderFilterable isFilterable', text, isFilterable)



        const isHeaderFilterable = isFilteringSupported && isFilterable
        // console.log('### isHeaderFilterable isHeaderFilterable', text, isHeaderFilterable)
        // console.log('### isHeaderFilterable ==')

        return isHeaderFilterable
      },
      sortableHeaders: function(headers) {
        // console.log('sortableHeaders', headers)
        return headers.map((header) => {
          let sortBy = this.computedDefaultPagination.sortColumn
          let sortDirection = this.computedDefaultPagination.sortDirection

          let classSet = new Set(header.class)
          let cellClassSet = new Set(header.cellClass)

          classSet.delete('sorted')
          cellClassSet.delete('sorted')

          classSet.delete('ascending')
          classSet.delete('descending')

          cellClassSet.delete('ascending')
          cellClassSet.delete('ascending')

          if (sortBy === header.value) {
            classSet.add('sorted')
            cellClassSet.add('sorted')

            classSet.add(sortDirection)
            cellClassSet.add(sortDirection)
          }

          header.class = [ ...classSet ]
          header.cellClass = [ ...cellClassSet ]

          return header
        })
      },
      headersForAccess: function(headers) {
        // console.log('headersForAccess')

        return headers.filter((object) => {
          // console.log('0 HeadersForAccess:', object.value)

          let retVal = true

          let hasRole = Object.hasOwn(object, 'role')
          let hasScope = Object.hasOwn(object, 'scope')
          let hasAccess = Object.hasOwn(object, 'access')

          if (hasRole || hasScope || hasAccess) {
            if (hasRole) {
              // console.log('1 HeadersForAccess role specified:', object.value, object.role, hasRole)
              retVal = this.userHasRequiredRole(object.role)
            } else if (hasScope || hasAccess) {
              // console.log('2 HeadersForAccess scope specified:', object.value, object.scope, hasScope)
              // console.log('2 HeadersForAccess access specified:', object.value, object.access, hasAccess)
              if (hasScope && hasAccess) {
                retVal = this.userHasRequiredScopeAndAccess(
                    object.scope,
                    object.access,
                )
              } else if (hasScope) {
                retVal = this.userHasRequiredScope(object.scope)
              } else if (hasAccess) {
                retVal = this.userHasRequiredAccess(object.access)
              }
            }
          } else {
            // header does not specify perms
          }
          return retVal
        })

        // return headers.filter(object => {
        //   let retVal = true
        //   let hasRole = Object.hasOwn(object, 'roles')
        //   if (hasRole) {
        //     retVal = this.userHasRequiredRole(object.roles)
        //   }
        //   return retVal
        // })
      },

      setSortColumn: function(header) {
        // console.log('setSortColumn header', header)
        // console.log('setSortColumn value', header.value)
        let oldSortColumn = this.computedDefaultPagination.sortColumn
        let newSortColumn = header.value

        let oldSortDirection = this.computedDefaultPagination.sortDirection
        let newSortDirection

        if (oldSortDirection === 'ascending') {
          newSortDirection = 'descending'
        } else if (oldSortDirection === 'descending') {
          newSortDirection = 'ascending'
        }

        if (oldSortColumn === newSortColumn) {
          this.computedDefaultPagination.sortDirection = newSortDirection
        } else {
          this.computedDefaultPagination.sortColumn = newSortColumn
        }
      },
      getSortIcon: function(header) {
        // console.log('SortIcon header', header)
        let icon
        let sortDirection = this.computedDefaultPagination.sortDirection

        if (header.sortable === true) {
          icon = '$sortSolid'
          if (header.class.includes('sorted')) {
            if (sortDirection === 'ascending') {
              icon = 'mdi-chevron-up-circle'
            } else if (sortDirection === 'descending') {
              icon = 'mdi-chevron-up-circle'
            }
          }
        }
        return icon
      },

      eventHandlerPageCount: function(event) {
        // console.log('eventHandlerPageCount event', event)
        this.computedDefaultPagination.pages = event

      },
      eventHandlerOptions: function(event) {
        // console.log('eventHandlerOptions event', event)
      },
      eventHandlerPagination: function(event) {
        // console.log('eventHandlerPagination event', event)
        this.filteredPagination = event
        this.$emit('filter:changed', this.computedFilterString)
      },
      eventHandlerSort($event, context) {
        // console.log('eventHandlerSort $event', $event)
        // console.log('eventHandlerSort context', context)
        if (context === 'sortColumn') {
          this.computedDefaultPagination.sortColumn = $event
        } else if (context === 'sortDirection') {
          let retVal
          if ($event === true) {
            retVal = 'descending'
          } else if ($event === false) {
            retVal = 'ascending'
          }
          this.computedDefaultPagination.sortDirection = retVal
        }
      },
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
      // console.log('items', this.items)
      // console.log('mounted $slots', this.$slots)
      // console.log('mounted $scopedSlots', this.$scopedSlots)
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>


  .header-content {
    .header-top {
      .top-left {
        .title {

        }
        .subtitle {
          line-height: 1rem;
        }
      }
    }
  }
  /*
  .bs-table-container.debug .header-content {
    background-color: rgba(255, 69, 0, 0.08);
  }
  */
</style>
