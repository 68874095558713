<!--
Copyright ThreatBlockr Inc 2023
Created by ejohnson on 12/6/23

// https://github.com/mengxiong10/vue2-datepicker.git
-->
<template>

  <!--:placeholder=computedPlaceholder-->
  <!--:confirm="confirm"-->

  <date-picker v-model="localValue"
               style="width: 100%"
               value-type="YYYY-MM-DDTHH:mm:ssZ"
               format="MM/DD/YY, hh:mm a"

               :disabled-date="computedDisabledDate"
               :disabled-time="computedDisabledTime"

               :append-to-body="false"
               type="datetime"
               input-class="v-input"

               time-title-format="MM/DD/YY, hh:mm a"

               confirm
               :show-time-panel="showTimePanel"
               :class="{ 'bs-field-dirty': computedIsDirty }"

               v-bind="$attrs"
               v-on="$listeners">

    <template v-slot:input="slotProps">
      <v-text-field v-bind="{ ...slotProps.props, ...textFieldProps }"
                    v-on="slotProps.events"

                    :disabled="$attrs.disabled || loading"
                    :loading="loading"

                    hide-details="auto"
                    clear-icon="mdi-close-circle"
                    outlined>
        <template v-slot:prepend-inner>
          <v-icon class="mr-1">mdi-calendar-clock</v-icon>
        </template>
      </v-text-field>
    </template>

    <template v-slot:icon-calendar>
      <v-icon class=""></v-icon>
    </template>
    <template v-slot:icon-clear>
      <v-icon class="">mdi-close-circle</v-icon>
    </template>
    <template v-slot:footer="{ emit }">
      <v-btn-toggle v-model="showTimePanel"
                    dense
                    mandatory>

        <v-btn :value="false">
          <v-icon>mdi-calendar-blank-outline</v-icon>
        </v-btn>
        <v-btn :value="true">
          <v-icon>mdi-clock-outline</v-icon>
        </v-btn>
      </v-btn-toggle>

    </template>

  </date-picker>

</template>

<script>
  // https://github.com/mengxiong10/vue2-datepicker/issues/671
  // https://github.com/mengxiong10/vue2-datepicker/issues/584
  // https://github.com/mengxiong10/vue2-datepicker/issues/616
  // https://github.com/mengxiong10/vue2-datepicker/issues/457 https://codesandbox.io/p/sandbox/open-time-panel-8wm3g     detect click date vs time

  import DatePicker from 'vue2-datepicker'
  import 'vue2-datepicker/index.css'

  import { format, formatISO, getDayOfYear, getTime, isFuture, isPast, isToday, isValid, isWithinInterval, parseISO, setMilliseconds } from 'date-fns'
  import PreferencesMixin from '@/mixins/PreferencesMixin.js'
  import StickyDiscriminatorMixin from '@/mixins/StickyDiscriminatorMixin.js'
  import { compact, first, isEqual, last, orderBy } from 'lodash'
  import BSDatetimeRangePickerMixin from '@/mixins/BSDatetimeRangePickerMixin.js'
  import helpers from '@/helpers/helpers.js'

  export default {
    name: 'BSBaseDatetimePicker',
    props: {
      // https://vuejs.org/guide/components/props.html#prop-validation
      textFieldProps: {
        type: Object,
        default: function() {
          return {}
        },
      },
      value: {
        type: [ String, Array ],
        default: null,
      },
      items: {
        type: Array,
        required: false,
        default(rawProps) {
          return []
        },
      },

      disabledDate: {
        type: Function,
      },
      disabledTime: {
        type: Function,
      },
      futureOnly: {
        type: Boolean,
        default: false,
      },
      pastOnly: {
        type: Boolean,
        default: false,
      },

      loading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showTimePanel: false,
        default: null,
      }
    },
    components: {
      'date-picker': DatePicker,
    },
    mixins: [
      // StickyDiscriminatorMixin,
      // BSDatetimeRangePickerMixin,
    ],
    watch: {},
    computed: {
      // TODO: new style for v-model props
      // TODO: new hotness for v-model props
      localValue: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
        },
      },
      computedIsDirty: function() {
        // console.log('computedIsDirty')
        //                :class="{ 'discriminator-dirty': this.localValue !== this.default }"
        // console.log('computedIsDirty this.localValue', this.localValue)
        // console.log('computedIsDirty this.default', this.default)

        const equal = isEqual(this.localValue, this.default)
        // console.log('computedIsDirty equal', equal)

        const isDirty = equal !== true
        // const isDirty = this.localValue !== this.default
        // console.log('computedIsDirty isDirty', isDirty)
        // console.log('computedIsDirty ///////////////////////////////////////////////////')

        // return true
        return isDirty
      },
      computedDisabledDate: function() {
        // console.log('computedDisabledDate')
        let retVal = this.disabledDate
        if (this.futureOnly) {
          retVal = this.isDatePastFunction
          // retVal = this.dateIsInPast
          // retVal = this.isDateFutureFunction
        } else if (this.pastOnly) {
          retVal = this.isDateFutureFunction
          // retVal = this.pastDateOnlyFunc
        } else if (this.items.length) {
          retVal = this.dateOutsideFunc
          // retVal = this.betweenDatesFunc
        }
        return retVal
      },
      computedDisabledTime: function() {
        // console.log('computedDisabledDate')
        let retVal = this.disabledDate
        if (this.futureOnly) {
          retVal = this.isTimePastFunction
          // retVal = this.futureTimeOnlyFunc
        } else if (this.pastOnly) {
          retVal = this.isTimeFutureFunction
          // retVal = this.pastTimeOnlyFunc
        } else if (this.items.length) {
          retVal = this.timeOutsideFunc
          // retVal = this.betweenDatesFunc
        }
        return retVal
      },
    },
    methods: {
      // start dynamic blackout /////////////////////////////////////////////////////
      // start dynamic blackout /////////////////////////////////////////////////////
      // start dynamic blackout /////////////////////////////////////////////////////
      dateOutsideFunc: function(datetime) {
        // console.log('dateOutsideFunc')
        return !this.dateBetweenFunc(datetime)
      },
      timeOutsideFunc: function(datetime) {
        // console.log('dateOutsideFunc')
        return !this.timeBetweenFunc(datetime)
      },
      dateBetweenFunc: function(datetime) {
        // console.log('dateBetweenFunc internal')
        let datetimeType = typeof datetime

        const consideredDate = getDayOfYear(datetime)
        // console.log('dateBetweenFunc internal consideredDate', consideredDate)

        const startDatetimeString = this.computedOldest
        const endDatetimeString = this.computedNewest

        const startDatetime = parseISO(startDatetimeString)
        const endDatetime = parseISO(endDatetimeString)

        const startDatetimeDayOfYear = getDayOfYear(startDatetime)
        const endDatetimeDayOfYear = getDayOfYear(endDatetime)
        // console.log('dateBetweenFunc internal startDatetimeDayOfYear', startDatetimeDayOfYear)
        // console.log('dateBetweenFunc internal endDatetimeDayOfYear', endDatetimeDayOfYear)

        const dayOfYearIsAfter = consideredDate >= startDatetimeDayOfYear
        const dayOfYearIsBefore = consideredDate <= endDatetimeDayOfYear

        let shouldShowDay = dayOfYearIsAfter && dayOfYearIsBefore
        // console.log('dateBetweenFunc internal shouldShowDay', shouldShowDay)

        return shouldShowDay
      },
      timeBetweenFunc: function(datetime) {
        // console.log('timeBetweenFunc internal datetime', datetime)

        const startDatetimeString = this.computedOldest
        const endDatetimeString = this.computedNewest
        const startDatetime = parseISO(startDatetimeString)
        const endDatetime = parseISO(endDatetimeString)
        // console.log('timeBetweenFunc internal startDatetime', startDatetime)
        // console.log('timeBetweenFunc internal endDatetime', endDatetime)

        const interval = [ startDatetime, endDatetime ]
        let isDatetimeInRange = this.datetimeInRange(datetime, interval)
        // console.log('timeBetweenFunc internal isDatetimeInRange', isDatetimeInRange)

        // console.log('timeBetweenFunc internal /////////////////////////////////////////////////')

        return isDatetimeInRange
      },
      // end dynamic blackout /////////////////////////////////////////////////////
      // end dynamic blackout /////////////////////////////////////////////////////
      // end dynamic blackout /////////////////////////////////////////////////////
      isTimePastFunction: function(datetime) {
        // console.log('isTimePastFunction datetime', format(datetime, 'aaa HH:mm:ss:SSSS'))

        let comparison
        comparison = isPast(datetime)

        // console.log('isTimePastFunction comparison', comparison)
        // console.log('isTimePastFunction //////////////////////////////////////////////////////////////')

        return comparison
      },
      isTimeFutureFunction: function(datetime) {
        // console.log('isTimeFutureFunction datetime', format(datetime, 'aaa HH:mm:ss:SSSS'))
        // console.log('isTimeFutureFunction datetime', format(datetime, 'MM/dd/yyyy HH:mm:ss:SSSS aaa'))

        let comparison
        comparison = isFuture(datetime)

        // console.log('isTimeFutureFunction comparison', comparison)
        // console.log('isTimeFutureFunction //////////////////////////////////////////////////////////////')

        return comparison
      },
      isDatePastFunction: (datetime) => {
        // console.log('isDatePastFunction datetime', datetime)

        let comparison
        const dateTimeIsToday = isToday(datetime)
        if (dateTimeIsToday) {
          comparison = false
        } else {
          comparison = isPast(datetime)
        }

        // console.log('isDatePastFunction comparison', comparison)
        // console.log('isDatePastFunction //////////////////////////////////////////////////////////////')

        return comparison
      },
      isDateFutureFunction: (datetime) => {
        // console.log('isDateFutureFunction datetime', datetime)

        let comparison
        const dateTimeIsToday = isToday(datetime)
        if (dateTimeIsToday) {
          comparison = false
        } else {
          comparison = isFuture(datetime)
        }

        // console.log('isDateFutureFunction comparison', comparison)
        // console.log('isDateFutureFunction //////////////////////////////////////////////////////////////')

        return comparison
      },
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  /*
  .success {
    background-color: #1d7c66 !important;
    border-color: #1d7c66 !important;
  }
  */
</style>
