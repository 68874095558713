var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-block d-flex flex-column justify-center fill-width",class:{
    'divider-bottom': _vm.showTitleDivider,
    'divider-dotted': _vm.showTitleDivider && _vm.titleDividerStyle === 'dotted',
    'divider-dashed': _vm.showTitleDivider && _vm.titleDividerStyle === 'dashed',
    'divider-double': _vm.showTitleDivider && _vm.titleDividerStyle === 'double',

     }},[(_vm.card)?[_c('div',{staticClass:"d-flex align-center fill-width",class:_vm.computedClasses.paddingClass},[_c('div',{staticClass:"d-flex align-center flex-gap-2"},[(_vm.hasPrependSlot)?[_vm._t("prepend")]:_vm._e(),_c('div',{class:_vm.computedClasses.titleClass},[_vm._t("title",function(){return [_vm._v(" "+_vm._s(_vm.title)+" ")]})],2),(_vm.count)?_c('div',{staticClass:"align-self-end text-subtitle-1 gray--text nowrap"},[_vm._v("("+_vm._s(_vm._f("localeString")(_vm.count))+") ")]):_vm._e(),(_vm.subtitle)?_c('div',{staticClass:"align-self-end text-subtitle-1 gray--text nowrap"},[_vm._v(_vm._s(_vm.subtitle)+" ")]):_vm._e(),(_vm.hasTopLeftSlot)?[_vm._t("topLeft")]:_vm._e()],2),(_vm.hasTopRightSlot)?_c('div',{staticClass:"d-flex align-center flex-grow-1 justify-end"},[_vm._t("topRight")],2):_vm._e()]),(_vm.hasBottomSlots)?_c('div',{staticClass:"d-flex align-center mt-4"},[(_vm.hasBottomLeftSlot)?_c('div',{staticClass:"d-flex align-center"},[_vm._t("bottomLeft")],2):_vm._e(),(_vm.hasBottomRightSlot)?_c('div',{staticClass:"d-flex align-center flex-grow-1 justify-end"},[_vm._t("bottomRight")],2):_vm._e()]):_vm._e()]:[_c('div',{staticClass:"d-flex align-center pb-8"},[_c('div',{staticClass:"text-h4"},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.count)?_c('div',{staticClass:"align-self-baseline text-subtitle-1 ml-2 gray--text nowrap"},[_vm._v("("+_vm._s(_vm._f("localeString")(_vm.count))+") ")]):_vm._e(),(_vm.subtitle)?_c('div',{staticClass:"align-self-baseline text-subtitle-1 ml-2 gray--text nowrap"},[_vm._v(_vm._s(_vm.subtitle)+" ")]):_vm._e()]),_c('div',{staticClass:"mr-auto d-flex"},[_vm._t("left")],2),_c('div',{staticClass:"ml-auto d-flex"},[_vm._t("right")],2)])],(_vm.debug)?_c('div',[_c('bs-print',{attrs:{"value":{showTitleDivider: _vm.showTitleDivider}}}),_c('bs-print',{attrs:{"value":{computedClasses: _vm.computedClasses}}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }