import { formatISO, getDayOfYear, isWithinInterval, parseISO } from 'date-fns'
import { first, last, orderBy } from 'lodash'
import helpers from '@/helpers/helpers.js'

export default {
    data() {
        return {}
    },
    watch: {},
    computed: {
        /*
         computedOldest: function() {
         console.log('computedOldest')
         console.log('computedOldest this.items', this.items)

         let sortedArray = this.items
         // let sortedArray = this.computedOrderedItems
         console.log('computedOldest sortedArray', sortedArray)

         let row = first(sortedArray)
         console.log('computedOldest row', row)

         if (!row) return
         let datetime = row.datetime
         console.log('computedOldest datetime', datetime)

         return datetime
         },
         */
        /*
         computedNewest: function() {
         // console.log('computedNewest')
         let sortedArray = this.computedOrderedItems
         let row = last(sortedArray)

         if (!row) return
         let datetime = row.datetime

         // console.log('computedNewest datetime', datetime)
         return datetime
         },
         */
    },
    methods: {
        /*
         getRangeString: function(startDatetime, endDatetime) {
         const parameters = {
         startDatetime: startDatetime,
         endDatetime: endDatetime,
         }
         const datetimeString = this.datetimeRangeStringFormat(parameters)
         return datetimeString
         },
         */

        // used by prog
        datetimeRangeStringFormat: function(parameters) {
            const formatString = 'MM/dd/yy, hh:mm aaa'
            const startDatetime = parameters.startDatetime
            const endDatetime = parameters.endDatetime

            let retVal = ''
            if (startDatetime && endDatetime) {
                const formatStartDatetime = helpers.formatDatetime(startDatetime, formatString)
                const formatEndDatetime = helpers.formatDatetime(endDatetime, formatString)

                retVal = `${ formatStartDatetime } to ${ formatEndDatetime }`

            }

            return retVal
        },
        /*
         dateOutsideFunc: function(datetime) {
         // console.log('dateOutsideFunc')
         return !this.dateBetweenFunc(datetime)
         },
         */
        /*
         timeOutsideFunc: function(datetime) {
         // console.log('timeOutsideFunc')
         return !this.timeBetweenFunc(datetime)
         },
         */
        // used by ubertable
        datetimeInRange: function(datetimeString, datetimeStringRange) {
            console.log('datetimeInRange mixin datetimeStringRange', datetimeStringRange)

            if (!datetimeStringRange.length) return true
            // console.log('datetimeInRange mixin datetime', datetime)
            // console.log('datetimeInRange mixin datetimeRange', datetimeRange)
            // console.log('datetimeInRange mixin datetimeRange.length', datetimeRange.length)

            // console.log('datetimeInRange internal datetimeObject', datetimeObject)

            const datetime = parseISO(datetimeString)
            datetime.setSeconds(0, 0)

            const startDatetimeString = datetimeStringRange[0]
            const endDatetimeString = datetimeStringRange[1]
            console.log('datetimeInRange mixin startDatetimeString', startDatetimeString)
            console.log('datetimeInRange mixin endDatetimeString', endDatetimeString)

            const startDatetime = parseISO(startDatetimeString)
            startDatetime.setSeconds(0, 0)
            // need to remove seconds
            const endDatetime = parseISO(endDatetimeString)
            endDatetime.setSeconds(0, 0)

            let interval = {
                start: startDatetime,
                end: endDatetime,
            }
            console.log('datetimeInRange internal interval', interval)

            console.log('datetimeInRange mixin datetime', datetime)
            console.log('datetimeInRange mixin startDatetime', startDatetime)
            console.log('datetimeInRange mixin endDatetime', endDatetime)

            let isWithinIntervalBool = isWithinInterval(datetime, interval)

            console.log('datetimeInRange mixin isWithinIntervalBool datetime', datetime)
            console.log('datetimeInRange mixin isWithinIntervalBool interval.start', interval.start)
            console.log('datetimeInRange mixin isWithinIntervalBool interval.end', interval.end)
            console.log('datetimeInRange mixin isWithinIntervalBool', isWithinIntervalBool)
            console.log('datetimeInRange mixin ////////////////////////////////')

            return isWithinIntervalBool
        },
        /*
         dateBetweenFunc: function(datetime) {
         console.log('dateBetweenFunc')
         let datetimeType = typeof datetime

         const datetimeDayOfYear = getDayOfYear(datetime)

         const startDatetime = this.computedOldest
         const endDatetime = this.computedNewest

         const startDatetimeDayOfYear = getDayOfYear(startDatetime)
         const endDatetimeDayOfYear = getDayOfYear(endDatetime)

         const dayOfYearIsAfter = datetimeDayOfYear >= startDatetimeDayOfYear
         const dayOfYearIsBefore = datetimeDayOfYear <= endDatetimeDayOfYear

         let shouldShowDay = dayOfYearIsAfter && dayOfYearIsBefore

         return shouldShowDay
         },
         */
        /*
         timeBetweenFunc: function(datetime) {
         console.log('timeBetweenFunc')

         // const startDatetime = this.computedResultParameters.startDatetime
         // const endDatetime = this.computedResultParameters.endDatetime

         const startDatetime = this.computedOldest
         const endDatetime = this.computedNewest

         const isAfter = datetime > startDatetime
         const isBefore = datetime < endDatetime

         let retVal = isAfter && isBefore
         return retVal
         },
         */
    },
}
