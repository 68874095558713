import axios from 'axios'
import { get, isEqual } from 'lodash'
import router from '@/router/index.js'

const state = () => ({
    focused: false,
    jobsRepeat: null,

    jobs: [],

    isJobResultsBusy: false,
    isJobsBusy: false,

    results: null,
    /*
     results: {
     parameters: {},
     entries: [],
     },
     */
    flashId: null,
})

const MOCK_CALL_DELAY = 50

// const POLL_FREQUENCY = 5000
// const POLL_FREQUENCY = 10000
// const POLL_FREQUENCY = 15000
// const POLL_FREQUENCY = 25000
const POLL_FREQUENCY = 30000
// const POLL_FREQUENCY = 60000

// this.$store.getters['iocSearch/isOpenMarketplaceSuccessModal']
const getters = {
    focused: (state, getters) => {
        // console.log('VUEX UB getters focused')
        return state.focused
    },
    pollFrequency: (state, getters) => {
        // console.log('VUEX UB getters pollFrequency')

        // const currentRoute = router.currentRoute.name
        // console.log('VUEX UB getters pollFrequency POLL_FREQUENCY', POLL_FREQUENCY)
        // console.log('VUEX UB getters pollFrequency currentRoute', currentRoute)

        let pollFrequency = POLL_FREQUENCY
        if (state.focused) {
            pollFrequency = POLL_FREQUENCY / 2
        }
        return pollFrequency
    },
    results: (state, getters) => {
        return state.results
    },
    resultParameters: (state, getters) => {
        return state.results?.parameters
    },
    resultEntries: (state, getters) => {
        return state.results?.entries
    },
    status: (state, getters) => {
        // console.log('VUEX UB getters status')
        // console.log('VUEX UB getters status.hasJobs', getters.hasJobs)
        // console.log('VUEX UB getters status.firstJob?.status', getters.firstJob?.status)
        // console.log('VUEX UB getters status.isJobResultsBusy', getters.isJobResultsBusy)
        // console.log('VUEX UB getters status.hasJobResults', getters.hasJobResults)

        let status = getters.firstJob?.status
        if (status === 'COMPLETE' && getters.hasJobResults === false) {
            status = 'FETCHING'
        }
        // console.log('VUEX UB getters status /////////////////////', status)

        return status
    },
    /*
     status: (state, getters) => {
     // console.log('VUEX UB getters status')
     // console.log('VUEX UB getters status.hasJobs', getters.hasJobs)
     // console.log('VUEX UB getters status.firstJob?.status', getters.firstJob?.status)
     // console.log('VUEX UB getters status.isJobResultsBusy', getters.isJobResultsBusy)
     // console.log('VUEX UB getters status.hasJobResults', getters.hasJobResults)

     let status = getters.firstJob?.status
     if (status === 'COMPLETE' && getters.hasJobResults === false) {
     status = 'FETCHING'
     }
     // console.log('VUEX UB getters status /////////////////////', status)

     return status
     },
     */
    flashId: (state, getters) => {
        return state.flashId
    },
    jobs: (state, getters) => {
        return state.jobs
    },
    firstJob: (state, getters) => {
        let firstJob = state.jobs[0]
        /*
         if (firstJob) {
         let status = firstJob.status
         if (status === 'COMPLETE' && getters.hasJobResults === false) {
         firstJob.status = 'FETCHING'
         }

         }
         */
        return firstJob
    },
    hasIncompleteJob: (state, getters) => {
        let hasIncompleteJob = false
        let hasJobs = getters.hasJobs
        if (hasJobs) {
            let status = getters.firstJob.status
            hasIncompleteJob = status !== 'COMPLETE'
        }
        return hasIncompleteJob
    },
    hasFailedJob: (state, getters) => {
        // console.log('VUEX UB getters hasFailedJob')

        let hasFailedJob = false
        let hasJobs = getters.hasJobs
        if (hasJobs) {
            let status = getters.firstJob.status
            // console.log('VUEX UB getters hasFailedJob status', status)

            hasFailedJob = status === 'FAILED'
        }
        // console.log('VUEX UB getters hasFailedJob', hasFailedJob)

        return hasFailedJob
    },
    hasCompleteJob: (state, getters) => {
        return getters.firstJob?.status === 'COMPLETE'
    },
    hasJobResults: (state, getters) => {
        // console.log('VUEX UB getters hasJobResults results', getters.results)
        return getters.results !== null
        // return getters.results
        // return getters.results?.entries.length > 0
    },
    isJobResultsBusy: (state, getters) => {
        return state.isJobResultsBusy
    },
    isJobsBusy: (state, getters) => {
        return state.isJobsBusy
    },
    hasJobs: (state, getters) => {
        return getters.jobCount > 0
    },
    jobCount: (state, getters) => {
        return state.jobs.length
    },
    jobsRepeat: (state, getters) => {
        return state.jobsRepeat
    },
}

// $store.dispatch('auth/authRedirect')
const actions = {
    setFocused(context, focused) {
        context.commit('SET_FOCUSED', focused)
    },

    /*
     enrichJobResultsForIp: function() {
     // console.log('enrichJobResultsForIp')

     },
     */
    initiateLogAnalysis: ({ commit, state, getters, dispatch, rootState, rootGetters }, parameters) => {
        // console.log('VUEX UB actions initiateLogAnalysis parameters ///////////////////////////', parameters)

        commit('RESET_JOB_RESULTS')
        // dispatch('stopJobsRepeat')

        let apiEndpoint = '/jobs/instances/blocks'

        // start mock stuff ///////////////////////////////////////////////////////////////////////////////
        // start mock stuff ///////////////////////////////////////////////////////////////////////////////
        // start mock stuff ///////////////////////////////////////////////////////////////////////////////

        // let mock = new MockAdapter(axios, { delayResponse: MOCK_CALL_DELAY })
        /*
         let payload = {
         uuid: faker.string.uuid(),
         startDatetime: startDatetime,
         endDatetime: endDatetime,
         status: 'SCHEDULED',
         instances: instances,
         }
         */
        // console.log('VUEX UB actions initiateLogAnalysis payload', payload)
        // mock.onPost(apiEndpoint).reply(200, payload)

        // end mock stuff ///////////////////////////////////////////////////////////////////////////////
        // end mock stuff ///////////////////////////////////////////////////////////////////////////////
        // end mock stuff ///////////////////////////////////////////////////////////////////////////////

        axios.post(apiEndpoint, parameters)
            .then(response => {
                // console.log('VUEX UB actions initiateLogAnalysis axios.post', response.data)

                // set the status
                commit('SET_JOBS', [ response.data ])
                // create a repeating checker
                // dispatch('repeatJob')

                const dispatchObject = {
                    type: 'flash/addFlash',
                    flashType: 'info',
                    title: 'Analysis Scheduled',
                    progress: response.data,
                }

                /*
                 dispatch(dispatchObject, { root: true })
                 .then(result => {
                 // console.log('VUEX UB actions initiateLogAnalysis addFlash then', result)
                 // commit('SET_JOB_FLASH_ID', result.id)
                 })
                 .catch(error => {
                 // console.log('VUEX UB actions initiateLogAnalysis addFlash catch', error)
                 })
                 .finally(() => {
                 // console.log('VUEX UB actions initiateLogAnalysis addFlash finally')
                 // mock.restore()
                 })
                 */
            })
            .catch(error => {
                // console.log('VUEX UB actions initiateLogAnalysis catch', error)
            })
            .finally(() => {
                // console.log('VUEX UB actions initiateLogAnalysis finally')
                // mock.restore()
            })

    },

    /*
     getJobsStart: ({ commit, state, getters, dispatch }) => {
     // console.log('VUEX UB actions getJobsStart')

     const job = setTimeout(() => {
     dispatch('getJobs')
     }, getters.pollFrequency)

     },
     */
    getJobsRepeat: ({ commit, state, getters, dispatch }) => {
        // console.log('VUEX UB actions getJobsRepeat')

        const job = setTimeout(() => {
            dispatch('getJobs')
            dispatch('getJobsRepeat')
        }, getters.pollFrequency)
        // console.log('vuex actions repeatJob jobJob', jobJob)
        commit('SET_JOBS_REPEAT', job)
    },
    /*
     getJobsRepeat: ({ commit, state, getters, dispatch }) => {
     // console.log('VUEX UB actions getJobsRepeat')

     const currentRoute = router.currentRoute.name
     // console.log('VUEX UB actions getJobsRepeat currentRoute', currentRoute)
     // console.log('VUEX UB actions getJobsRepeat pollFrequency', getters.pollFrequency)

     dispatch('stopJobsRepeat')
     dispatch('getJobs')

     const job = setInterval(() => {
     dispatch('getJobs')
     }, getters.pollFrequency)

     // console.log('vuex actions repeatJob jobJob', jobJob)
     commit('SET_JOBS_REPEAT', job)
     // console.log('vuex actions repeatJob context.getters.jobJob', getters.jobJob)
     },
     */
    getJobs: ({ commit, state, getters, dispatch }) => {
        // console.log('VUEX UB actions getJobs')
        const isJobResultsBusy = state.isJobResultsBusy
        const isJobsBusy = state.isJobsBusy
        const hasFailedJob = getters.hasFailedJob

        // console.log('VUEX UB actions getJobs isJobsBusy', isJobsBusy)
        // console.log('VUEX UB actions getJobs isJobResultsBusy', isJobResultsBusy)
        // console.log('VUEX UB actions getJobs hasFailedJob', hasFailedJob)

        if (!isJobsBusy && !isJobResultsBusy && !hasFailedJob) {
            let apiEndpoint = `/jobs/instances/blocks`

            commit('SET_JOBS_BUSY', true)

            axios.get(apiEndpoint)
                .then(response => {
                    // console.log('VUEX UB actions getJobs then response.data', response.data)
                    let responseData = response.data

                    commit('SET_JOBS', responseData)

                    // if has job
                    if (getters.hasJobs) {
                        // console.log('VUEX UB actions getJobs then hasJobs', getters.hasJobs)

                        dispatch({
                            type: 'flash/setStatusForFlashWithId',
                            progress: {
                                flashId: getters.firstJob.uuid,
                                status: getters.firstJob,
                            },
                        }, { root: true })
                    }

                    // if has completejob
                    // console.log('VUEX UB actions getJobs hasCompleteJob', getters.hasCompleteJob)
                    if (getters.hasCompleteJob) {
                        // dispatch('stopJobsRepeat')
                        dispatch('getJobResults')
                    }
                })
                .finally(() => {
                    // console.log('VUEX UB actions getJobs finally')
                    commit('SET_JOBS_BUSY', false)
                    // mock.restore()
                })

        }
    },
    stopJobsRepeat: ({ commit, state, getters, dispatch }) => {
        // console.log('vuex actions stopJobsRepeat', getters.jobsRepeat)
        clearTimeout(getters.jobsRepeat)
        // clearInterval(getters.jobsRepeat)
        commit('SET_JOBS_REPEAT', null)
    },

    getJobResults: ({ commit, state, getters, dispatch }) => {
        // console.log('VUEX UB actions getJobResults state', state)
        // console.log('VUEX UB actions getJobResults for uuid', getters.firstJob.uuid)
        // console.log('VUEX UB actions getJobResults for uuid', state.status.uuid)
        let apiEndpoint = `/jobs/instances/blocks/${ getters.firstJob.uuid }/data`
        // let apiEndpoint = `/jobs/instances/blocks/${ state.status.uuid }/data`

        // start mock stuff ///////////////////////////////////////////////////////////////////////////////
        // start mock stuff ///////////////////////////////////////////////////////////////////////////////
        // start mock stuff ///////////////////////////////////////////////////////////////////////////////

        // let mock = new MockAdapter(axios, { delayResponse: MOCK_CALL_DELAY })
        // let payload = UnexpectedBlocksFixture.getOne()
        // mock.onGet(apiEndpoint).reply(200, payload)
        commit('SET_JOB_RESULTS_BUSY', true)

        axios.get(apiEndpoint)
            .then(response => {
                // console.log('VUEX UB actions getJobResults then', response.data)

                // add _hasIpInfo
                let responseData = response.data
                responseData.entries = responseData.entries.map(item => {
                    return {
                        ...item,
                        _hasIpInfo: false,
                    }
                })
                commit('SET_JOB_RESULTS', responseData)
            })
            .finally(() => {
                // console.log('VUEX UB actions getJobResults finally')
                commit('SET_JOB_RESULTS_BUSY', false)
                // mock.restore()
            })
    },

    getIpInfo: ({ commit, state, getters, dispatch }, { type, ip }) => {
        // console.log('VUEX actions getIpInfo ///////////////////////////////', ip)

        let apiEndpoint = `/search/ipinfo/${ ip }`

        axios.get(apiEndpoint)
            .then(response => {
                // console.log('VUEX UB actions getIpInfo then response.data', response.data)
                // let results = getters.results

                let extendedInfo = response.data
                let results = getters.results
                // let results = Object.assign({}, getters.results)
                let resultsWithIp = results.entries.filter(object => object.address === ip)
                // console.log('VUEX UB actions getIpInfo then resultsWithIp', resultsWithIp)

                // add _hasIpInfo
                // let responseData = response.data
                let entries = results.entries.map(item => {
                    // let entries = resultsWithIp.entries.map(item => {
                    if (item.address === ip) {
                        return {
                            ...item,
                            whois: extendedInfo.whois,
                            reverseDns: extendedInfo.reverseDns,
                            _hasIpInfo: true,
                        }
                    } else {
                        return item
                    }
                    /*
                     return {
                     ...item,
                     whois: extendedInfo.whois,
                     reverseDns: extendedInfo.reverseDns,
                     _hasIpInfo: true,
                     }
                     */
                })
                commit('SET_JOB_RESULTS_ENTRIES', entries)


            })
            .finally(() => {
                // console.log('VUEX UB actions getIpInfo finally')
            })
    },

}

// $store.commit('auth/SET_JWT', response.data)
const mutations = {
    ////////////////////////////////////////////////
    // start mutations
    ////////////////////////////////////////////////
    SET_FOCUSED: (state, payload) => {
        state.focused = payload
    },

    RESET_JOB_RESULTS: (state) => {
        // console.log('VUEX UB mutations RESET_JOB_RESULTS')
        state.results = null
        /*
         state.results = {
         parameters: {},
         entries: [],
         }
         */

    },
    SET_JOB_RESULTS_BUSY: (state, payload) => {
        // console.log('VUEX UB mutations SET_JOB_RESULTS_BUSY', payload)
        state.isJobResultsBusy = payload
    },
    SET_JOBS_BUSY: (state, payload) => {
        // console.log('VUEX UB mutations SET_JOBS_BUSY', payload)
        state.isJobsBusy = payload
    },
    SET_JOB_RESULTS: (state, payload) => {
        // console.log('VUEX UB mutations SET_JOB_RESULTS', payload)
        state.results = payload
    },
    SET_JOB_RESULTS_ENTRIES: (state, payload) => {
        // console.log('VUEX UB mutations SET_JOB_RESULTS', payload)
        state.results.entries = [
            ...payload,
        ]
    },
    SET_JOBS: (state, payload) => {
        state.jobs = payload
    },
    /*
     SET_JOBS: (state, payload) => {
     // console.log('VUEX UB mutations SET_JOBS proposed', payload)
     let jobs = state.jobs
     // console.log('VUEX UB mutations SET_JOBS current', jobs)
     // console.log('VUEX UB mutations SET_JOBS isEqual(payload, jobs)', isEqual(payload, jobs))

     if (!isEqual(payload, jobs)) {
     state.jobs = payload
     }
     },
     */
    SET_JOBS_REPEAT: (state, job) => {
        // console.log('vuex mutations SET_JOBS_REPEAT')
        state.jobsRepeat = job
    },
    ////////////////////////////////////////////////
    // end mutations job center
    ////////////////////////////////////////////////

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}

