// import router from '@/router'
// import axios from 'axios'
// import { get } from 'lodash'

// this.$store.state['marketplace/successMessage']
const state = () => ({
    successMessage: null,
    isOpenMarketplaceSuccessModal: false,

})

// this.$store.getters['iocSearch/isOpenMarketplaceSuccessModal']
const getters = {
    successMessage: state => {
        return state.successMessage
    },
    isOpenMarketplaceSuccessModal: state => {
        return (state.successMessage !== null)
    },

}

// this.$store.dispatch('marketplace/closeIocSearchModal')
const actions = {
    setSuccessMessage(context, successMessage) {
        console.log('vuex marketplace actions setSuccessMessage', successMessage)
        context.commit('SET_MARKETPLACE_SUCCESS_MESSAGE', successMessage)
    },
    /*
     clearSuccessMessage(context) {
     context.commit('SET_MARKETPLACE_SUCCESS_MESSAGE', null)
     },
     */
    /*
     openMarketplaceSuccessModal(context, successMessage) {
     // console.log('vuex openIocSearchModal', successMessage)
     context.commit('SET_MARKETPLACE_SUCCESS_MESSAGE', successMessage)
     // console.log('vuex state.successMessage', context.state.successMessage)

     context.commit('SET_IS_OPEN_MARKETPLACE_SUCCESS_MODAL', true)
     },
     */
    closeMarketplaceSuccessModal(context) {
        // console.log('vuex closeIocSearchModal')
        context.commit('SET_IS_OPEN_MARKETPLACE_SUCCESS_MODAL', false)
        context.commit('SET_MARKETPLACE_SUCCESS_MESSAGE', null)
    },
}

// this.$store.commit('marketplace/SET_JWT', response.data)
const mutations = {
    SET_MARKETPLACE_SUCCESS_MESSAGE: (state, successMessage) => {
        console.log('vuex marketplace mutations SET_MARKETPLACE_SUCCESS_MESSAGE', successMessage)
        state.successMessage = successMessage
    },
    SET_IS_OPEN_MARKETPLACE_SUCCESS_MODAL: (state, isOpenMarketplaceSuccessModal) => {
        state.isOpenMarketplaceSuccessModal = isOpenMarketplaceSuccessModal
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

